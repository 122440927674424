import React, { useState } from "react";
import "./Filter.css";
import bwl from "./images/Biz Wise Logo.svg";
import bwl_w from "./images/BizWiseLogo_white.svg";
import people from "./images/people.svg";
import footer1 from "./images/footer1.png";
import footer2 from "./images/footer2.png";
import footer3 from "./images/footer3.png";
import footer4 from "./images/footer4.png";
import { useNavigate } from "react-router-dom";
import mappingData from "./MUNICIPALITY_REGION_MAPPING.json";
import Form from "react-bootstrap/Form";
import * as pbi from "powerbi-client";
import { Tooltip } from "antd";
import { slide as Menu } from "react-burger-menu";
import Joyride, { STATUS } from "react-joyride";

const Filter = () => {
  document.body.style.overflow = "auto";

  const [BusinessName, setBusinessName] = useState("");
  const [BusinessCategory, setBusinessCategory] = useState("");
  const [region, setRegion] = useState("All");
  const [municipality, setMunicipality] = useState("All");
  const navigate = useNavigate();

  const regions = Object.keys(mappingData);
  const mappingTable = mappingData;
  const [listState, setListState] = useState(mappingData["All"]);

  // to make sure that the fropdowns are interacted with
  const [businessSelection, setBusinessSelection] = useState(false);
  const [regSelec, setRegSelec] = useState(false);
  const [munSelec, setMunSelec] = useState(false);
  /**
   * Returns ***true*** if proceed button allowed - else ***false***
   */
  const proceedButton = businessSelection && regSelec && munSelec;

  // to enable tooltip while clicking
  const [open, setOpen] = useState(false);

  // determins the state of burger-menu open/closing
  const [isOpen, setIsOpen] = useState(false);

  const powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory
  );

  let config = {
    type: "visual",
    embedUrl: "https://app.powerbi.com/reportEmbed",
  };

  let element = powerbi.preload(config);

  /**
   * Runs when the 'proceed' button is clicked.
   *
   * @param {*} event used the button-click event as input
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (proceedButton) {
      sessionStorage.setItem("region", region);
      sessionStorage.setItem("municipality", municipality);
      sessionStorage.setItem("multiRegion", ["All"]);
      sessionStorage.setItem("multiMunicipality", ["All"]);
      navigate("/graph");
    }
  };

  const handleBusinessCategory = (event) => {
    setBusinessCategory(event.target.value);
    setBusinessSelection(true); // changing the dropdown selction, changes this state
  };

  const handleRegion = (event) => {
    setRegion(event.target.value);
    setListState(mappingTable[event.target.value]);
    setMunicipality("All");
    setRegSelec(true); // changing the value of dropdown changes this state
  };

  const handleMunicipality = (event) => {
    setMunicipality(event.target.value);
    setMunSelec(true); // changing the value of dropdown changes this state
  };

  // tooltip handlers for click enablement
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };

  /**
   * for handling mobile burger open and close
   * */
  const handleIsSliderOpen = () => {
    setIsOpen(!isOpen);
  };

  // for the contact-us card
  const steps = [
    {
      target: "body",
      content: "support@digiwise.com",
      placement: "center",
    },
  ];
  const [popupRun, setPopupRun] = useState(false);
  const handlePopupCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED].includes(status)) {
      setPopupRun(false);
    }
  };

  /**
   * This method (i think its a method........ 🤔🤷) handles the two state assignments when the 'Contact-Us' button is pressed in the mobile view.
   * */
  const handleClickContactUs = () => (setIsOpen(!isOpen), setPopupRun(true));

  return (
    <div className="bg-home">
      {/* for the contact-us card */}
      <Joyride
        steps={steps}
        run={popupRun}
        hideCloseButton
        callback={handlePopupCallback}
        styles={{
          options: {
            primaryColor: "#0C1A45",
          },
        }}
      />

      {/******** Header ********/}
      <div className="title">
        {/* ********* MobileView Buttons In Burger ********* */}
        <div className="hf-burger">
          <Menu
            left
            styles="menuStyles"
            isOpen={isOpen}
            onClose={handleIsSliderOpen}
            onOpen={handleIsSliderOpen}
          >
            <button
              className="landing-page-buttons"
              onClick={(() => navigate("/"), handleIsSliderOpen)}
            >
              Home
            </button>

            <button
              className="landing-page-buttons"
              onClick={() => navigate("/feedback")}
            >
              Feedback
            </button>
            <button
              className="landing-page-buttons"
              onClick={() => handleClickContactUs()}
            >
              Contact Us
            </button>
          </Menu>
        </div>

        {/* ********* Logo ********* */}
        <img src={bwl} alt="bizWise" className="bizWiseLogo" />
        <img src={bwl_w} alt="bizWise" className="bizWiseLogo-white" />

        {/* This is the header buttons in Desktop view */}
        <div className="nav-buttons">
          {/******** Home button ********/}
          <button
            id="home-button"
            className="landing-page-buttons"
            onClick={() => navigate("/")}
            style={{ color: "#F3890D" }}
          >
            Home
          </button>

          {/******** Feedback button ********/}
          <button
            id="feedback-button"
            className="landing-page-buttons"
            onClick={() => navigate("/feedback")}
          >
            Feedback
          </button>

          {/******** Contact Us button ********/}
          <button
            id="feedback-button"
            className="landing-page-buttons"
            onClick={() => setPopupRun(true)}
          >
            ContactUs
          </button>
        </div>

        {/* dummy item for getting the flex right in mobile view */}
        <div></div>
      </div>

      {/******** Form and Text div ********/}
      <div className="firstHeadRow">
        <div className="mainhead1">
          <span>Achieve</span>
          <span className="subhead"> Business Growth</span>
          <span> with Our Services </span>
          <div className="subtext">
            Business Advisory to find locations for opening a business based on
            market research factors like – Demographics, Crowd Footfall, Market
            Saturation, Neighborhood Attractions etc.
            <br></br>
            {
              /* for mobile */
              <img
                src={people}
                alt="bizWise"
                className="background-image-mob"
              />
            }
            <br></br>
            Choose your business type and location: Get Data Driven Predictions,
            Analytics and Insights.
          </div>
          <form onSubmit={handleSubmit} className="filterParent">
            <div className="filterForm">
              {/* ********* Business Category ********* */}
              <label className="text">
                Business Category<br></br>
                <select
                  className="dropDownBox"
                  onChange={handleBusinessCategory}
                  required={true}
                >
                  <option value="None">None</option>
                  <option value="Cafe">Cafe</option>
                </select>
              </label>

              {/* ********* Region ********* */}
              <label className="text">
                Region<br></br>
                <Form.Select
                  className="dropDownBox"
                  aria-label="Default select example"
                  onChange={handleRegion}
                >
                  {regions.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Form.Select>
              </label>

              {/* ********* Municipality ********* */}
              <label className="text">
                Municipality<br></br>
                <Form.Select
                  className="dropDownBox"
                  aria-label="Default select example"
                  value={municipality}
                  onChange={handleMunicipality}
                >
                  {listState.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Form.Select>
              </label>
            </div>
            {/* ********* Button ********* */}
            <Tooltip
              open={open}
              title={
                proceedButton != true
                  ? "Please select a particular business category, region and municipality to proceed"
                  : ""
              }
            >
              <input
                type="submit"
                name="submit"
                value="Predict Success"
                className="predictButton"
                onClick={proceedButton != true ? handleTooltipOpen : null}
                onMouseEnter={proceedButton != true ? handleTooltipOpen : null}
                onMouseLeave={handleTooltipClose}
              />
            </Tooltip>
          </form>
        </div>
        <div>
          <img src={people} alt="bizWise" className="background-image" />
        </div>
      </div>

      {/* Footer portion */}
      <div className="footer-bg">
        <div className="footer">
          <div className="footerItem">
            <img src={footer1} alt="bizWise" class="footerImage1" />
            <pre className="footertext">
              <span className="initialText">Target </span>Customers
            </pre>
            <pre className="footertext">Analysis</pre>
          </div>
          <div className="footerItem">
            <img src={footer2} alt="bizWise" class="footerImage2" />
            <pre className="footertext">
              <span className="initialText">Improve </span>
              leads
            </pre>
            <pre className="footertext">generation</pre>
          </div>
          <div className="footerItem">
            <img src={footer3} alt="bizWise" class="footerImage3" />
            <pre className="footertext">Go-to-market</pre>
            <pre className="footertext">
              <span className="initialText"> Strategy </span>
            </pre>
          </div>
          <div className="footerItem">
            <img src={footer4} alt="bizWise" class="footerImage4" />
            <pre className="footertext">
              <span className="initialText">Improve </span>
              sales
            </pre>
            <pre className="footertext">performance</pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
