import React, { useState } from "react";
import { json, useNavigate } from "react-router";
import bwl from "./images/Biz Wise Logo.svg";
import bwl_w from "./images/BizWiseLogo_white.svg";
import "./Feedback.css";
import background from "./images/feedback-bg.svg";
import { Tooltip } from "antd";
import { slide as Menu } from "react-burger-menu";
import Joyride, { STATUS } from "react-joyride";
import axios from "axios";

const Feedback = () => {
  document.body.style.overflow = "auto";
  document.body.style.backgroundColor = "#FFF";
  const navigate = useNavigate();
  const home = () => {
    navigate("/");
  };

  /**
   *********** Feedback Section **********
   */
  // #1
  const [userName, setUserName] = useState("");
  // #2
  const [userEmail, setUserEmail] = useState("");

  // feedback - stars ✨ #3
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [totalStars, setTotalStars] = useState(5);
  // this is just to enable/disable the proceed button
  let [starsGiven, setStarsGiven] = useState(false);
  // counting stars......
  const stars = [...Array(totalStars)].map((star, index) => {
    const currentRating = index + 1;
    return (
      <label key={currentRating + 1}>
        <input
          className="star-radio-button"
          type="radio"
          name="rating"
          value={currentRating}
          onChange={() => {
            setRating(currentRating);
            setStarsGiven(true);
          }}
        />
        <span
          className="star"
          style={{
            color: currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9",
          }}
          onMouseEnter={() => setHover(currentRating)}
          onMouseLeave={() => setHover(null)}
        >
          &#9733;
        </span>
      </label>
    );
  });
  // #4
  const [dataPoints, setDataPoints] = useState("");
  // #5
  const [similarUsecase, setSimilarUsecase] = useState("");
  // #6
  const [collab, setCollab] = useState("");
  const [msgDisplay, setMsgDisplay] = useState("none");

  /**
   * Handles sending the feedback to the Az Function
   * */
  const handleFeedbackSend = () => {
    // the azure functions url
    const feedbackUrl = "https://smbadvisoryapp.azurewebsites.net/api/feedback";
    // convertng the feedback to JSON format
    const feedbackJSON = {
      userName,
      userEmail,
      rating,
      dataPoints,
      similarUsecase,
      collab,
    };

    axios
      .post(feedbackUrl, feedbackJSON)
      .then((response) => {
        // checking the reposnse status
        if (response.status == 200) {
          console.debug("Feedback sent");
          // Extract the response body
          console.debug(response.data);
        } else {
          // error status
          console.error("Error: ", response.statusText);
          console.error(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // to enable tooltip while clicking
  const [open, setOpen] = useState(false);
  let [proceedButton, setProceedButton] = useState(false);
  proceedButton = starsGiven;

  // tooltip handlers for click enablement
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };

  // determins the state of burger-menu open/closing
  const [isOpen, setIsOpen] = useState();

  /**
   * Runs when the 'proceed' button is clicked.
   *
   * @param {*} event used the button-click event as input
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (proceedButton) {
      handleFeedbackSend();
      navigate("/");
    }
  };

  /**
   * for handling mobile burger open and close
   * */
  const handleIsSliderOpen = () => {
    setIsOpen(!isOpen);
  };

  // for the contact-us card
  const steps = [
    {
      target: "body",
      content: "support@digiwise.com",
      placement: "center",
    },
  ];
  const [popupRun, setPopupRun] = useState(false);
  const handlePopupCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED].includes(status)) {
      setPopupRun(false);
    }
  };

  return (
    <div className="feedback-body">
      {/* for the contact-us card */}
      <Joyride
        steps={steps}
        run={popupRun}
        hideCloseButton
        callback={handlePopupCallback}
        styles={{
          options: {
            primaryColor: "#0C1A45",
          },
        }}
      />

      {/******** Header ********/}
      <div className="title">
        {/* ********* MobileView Buttons In Burger ********* */}
        <div className="hf-burger">
          <Menu
            left
            styles="menuStyles"
            isOpen={isOpen}
            onClose={handleIsSliderOpen}
            onOpen={handleIsSliderOpen}
          >
            <button
              className="landing-page-buttons"
              onClick={() => navigate("/")}
            >
              Home
            </button>

            <button
              className="landing-page-buttons"
              onClick={(() => navigate("/feedback"), handleIsSliderOpen)}
            >
              Feedback
            </button>
            <button
              className="landing-page-buttons"
              onClick={() => (handleIsSliderOpen(), setPopupRun(true))}
            >
              Contact Us
            </button>
          </Menu>
        </div>

        {/* ********* Logo ********* */}
        <img src={bwl} alt="bizWise" className="bizWiseLogo" />
        <img src={bwl_w} alt="bizWise" className="bizWiseLogo-white" />

        {/* ********* WebView Buttons ********* */}
        <div className="nav-links">
          {/* Home button */}
          <button
            id="home-button"
            className="landing-page-buttons"
            onClick={() => navigate("/")}
          >
            Home
          </button>

          {/*Feedback button */}
          <button
            id="feedback-button"
            className="landing-page-buttons"
            onClick={() => navigate("/feedback")}
            style={{ color: "#F3890D" }}
          >
            Feedback
          </button>

          {/* Contact Us button */}
          <button
            id="feedback-button"
            className="landing-page-buttons"
            onClick={() => setPopupRun(true)}
          >
            ContactUs
          </button>
        </div>

        {/* dummy item for getting the flex right in mobile view */}
        <div></div>
      </div>
      {/******** Page Main Content ********/}
      <div className="feedback-content">
        <div className="feedback-heading">
          {/* <img src={arrow} alt="<-" className="arrowBtn" onClick={home} /> */}
          <span className="pageTitle"> Feedback </span>
          {/*Dummy span for flex allignment*/}
          <span style={{ marginRight: "22px" }}></span>{" "}
        </div>

        {/* main content */}
        <div className="main">
          <div>
            <img src={background} className="illustration"></img>
          </div>

          <form className="feedback-form" onSubmit={handleSubmit}>
            <span style={{ textAlign: "start" }}>
              This application is an attempt to show the concept of how data
              around us could be leveraged for Business advisory.
            </span>
            {/* 1. user's name */}
            <label className="feedback-fields feedback-imp">
              <span>1. Name</span>
              <div>
                <input
                  className="textBox"
                  type="text"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                    setProceedButton = true;
                  }}
                  required={true}
                  maxLength={50}
                />
              </div>
            </label>{" "}
            {/* 2. user's email */}
            <label className="feedback-fields feedback-imp">
              <span>2. Email</span>
              <div>
                <input
                  className="textBox"
                  type="email"
                  value={userEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                    setProceedButton = true;
                  }}
                  required={true}
                  maxLength={50}
                />
              </div>
            </label>{" "}
            {/*3.  ✨ */}
            <label className="feedback-fields feedback-imp">
              <span>3. How was your experience?</span>
              <div id="feedback-stars">{stars}</div>
            </label>
            {/* 4th feedback */}
            <label className="feedback-fields">
              <span>
                4. Which Business would you like to open and what data points
                you would like to know about?
              </span>
              <div>
                <textarea
                  className="textarea"
                  type="text"
                  value={dataPoints}
                  onChange={(e) => {
                    setDataPoints(e.target.value);
                    setProceedButton = true;
                  }}
                  maxLength={250}
                />
              </div>
            </label>
            {/* 5th feedback */}
            <label className="feedback-fields">
              <span>
                5. Similar Data(Demographics, Telecom - Crowd footfall, Business
                and Transaction Data) can be leveraged to enable other usecases.
                Could you think of any usecase from your industry?
              </span>
              <textarea
                className="textarea"
                type="text"
                value={similarUsecase}
                onChange={(e) => setSimilarUsecase(e.target.value)}
                maxLength={250}
              />
            </label>
            {/* Detailed feedback */}
            <label className="feedback-fields">
              <span>
                6. Are you interested to collaborate with us to explore the
                possibilities in your industry?
              </span>

              <label>
                <input
                  type="radio"
                  value="Yes"
                  onChange={(e) => (
                    setCollab(e.target.value), setMsgDisplay("block")
                  )}
                  checked={collab === "Yes"}
                  required={true}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  value="No"
                  onChange={(e) => (
                    setCollab(e.target.value), setMsgDisplay("none")
                  )}
                  checked={collab === "No"}
                  required={true}
                />
                No
              </label>
            </label>
            <span
              style={{
                display: msgDisplay,
                color: "#17be0f",
                textTransform: "capitalize",
                fontStyle: "italic",
                fontSize: "0.9rem",
              }}
            >
              Thank you. We will get back to you shortly.
            </span>
            {/* ********* Button ********* */}
            <Tooltip
              open={open}
              title={
                proceedButton != true ? "Please submit entire feedback" : ""
              }
            >
              <input
                type="submit"
                name="submit"
                value="Submit"
                className="predictButton"
                onClick={proceedButton != true ? handleTooltipOpen : null}
                onMouseEnter={proceedButton != true ? handleTooltipOpen : null}
                onMouseLeave={handleTooltipClose}
              />
            </Tooltip>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
