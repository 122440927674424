import "./App.css";
import Filter from "./components/Filter";
import Graph from "./components/Graph";
import Feedback from "./components/Feedback";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route index element={<Filter />} />
          <Route exact path="/graph" element={<Graph />} />
          <Route exact path="/feedback" element={<Feedback />} />
          <Route path="*" element={<Filter />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
