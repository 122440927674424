import React, { useState, useEffect } from "react";
import "./detailsPage.css";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { embedUrl, reportId } from "./Secret";
import { useNavigate } from "react-router-dom";
import mappingData from "./MUNICIPALITY_REGION_MAPPING.json";
import cafeData from "./MUNICIPALITY_REGION_CAFE_MAPPING.json";
import { Tooltip } from "@mui/material";
import Select from "react-select";
import Joyride, { STATUS } from "react-joyride";
import joyrideCarousel from "./joyride/carousel";

const SMBAnalytics = (props) => {
  const [graph1, setGraph1] = useState();
  const navigate = useNavigate();

  const home = () => {
    navigate("/");
  };

  const visualList = [graph1];
  const regions = Object.keys(mappingData);
  const mappingTable = mappingData;

  // Guided Navigation Section
  // 1. This determines the no of cards, its placement and content
  const steps = [
    {
      content: joyrideCarousel(
        "Similar Business Availability",
        "Competitior Analysis",
        "Neighborhood Business Influencers",
        "Business Financial growth in selected location"
      ),
      placement: "center",
      target: ".map-body",
    },
  ];
  // 2. This determins the state of the card (we are using the browsersessionstorage to store the state)
  const run = sessionStorage.getItem("popUpAppear-smba") == null ? true : false;
  // 3. This changes the state after closing the pop-up
  const handlePopupCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED].includes(status)) {
      sessionStorage.setItem("popUpAppear-smba", false);
    }
  };

  // Array that determines the filter dropdowns state
  const [showFilter, setShowFilter] = useState([]);

  // setting defaultRegion - for default filter
  const [defaultRegion, setDefaultRegion] = useState(
    sessionStorage.getItem("region")
  );

  // The string that sets/determines the value of municipality dropdown
  //  whenever there is a change in state
  const [defaultMunicipality, setDefaultMunicipality] = useState(
    sessionStorage.getItem("municipality")
  );

  const [defaultCafe, setDefaultCafe] = useState("All");

  // Initializong the 'array' as a state (so that the screen can be re-rendered when this gets updated)
  // with the list of municipalities under 'defaultRegion'
  const [listState, setListState] = useState(mappingTable[defaultRegion]);

  /**
   * **CafeList method**
   *
   * This method gets the region and municipality (by deafult, takes {@link defaultRegion} and {@link defaultMunicipality})
   * and gets the list of cafes from the Json array list.
   *
   * @param {*} cafeRegion Passed region to filter the cafe list
   * @param {*} cafeMunicipality Passed municipalities to filter the cafe list
   * @returns A list of cafe names based on the region&/municipality selection (or empty list if no cafe present for the particular selection)
   */
  const cafeList = (
    cafeRegion = defaultRegion,
    cafeMunicipality = defaultMunicipality
  ) => {
    // getting the list of municipality objects in the selected region/defaultRegion
    // this will be from MUNICIPALITY_REGION_CAFE_MAPPING
    const cafe_municip_list = cafeData[cafeRegion];

    // getting each 'object' from the muncipality list (cafe_municip_list) and
    // checking if the 'key' of that 'object' is what we selcted in the UI/default
    // and if it is, then we get the value of that object - which would be the list of the cafes in that municipality

    for (const municips of cafe_municip_list) {
      if (Object.keys(municips) == cafeMunicipality) {
        return municips[cafeMunicipality];
      }
    }
    return [];
  };

  /**
   * ListCafe - a state array that gets filled with the cafe names
   * according to the region/municipality selection
   */
  const [listCafes, setListCafes] = useState(cafeList());

  /**
   * **Filter List**
   *
   * The list to which both Region, Municipal and Cafe filter object gets added to.
   * In this end, this list/Array is passed in the {@link handleFilter} method.
   * */
  let customFilter = [];

  /**
   * Function to setup cafe filter object, and add it to {@link customFilter} at index `[0]`
   *
   * First item in 'VW_CAFE_DATA' is needed for the cafe to be selected in tables. while the second
   * 'VW_BUSINESS_LOCATIONS' is needed for the auto map selection of the cafe
   * @param {*} selection - The option that is selcted from the cafe dropdownlist
   */
  async function cafeFilter(selection) {
    {
      customFilter.push(
        {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "VW_CAFES_DATA",
            column: "CAFE_NAME",
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Or",
          conditions: [
            {
              operator: "Is",
              value: selection == "All" ? "" : selection,
            },
          ],
        },
        {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "VW_BUSINESS_LOCATIONS",
            column: "NAME",
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Or",
          conditions: [
            {
              operator: "Is",
              value: selection == "All" ? "" : selection,
            },
          ],
        }
      );
    }

    handleFilter();
  }

  /**
   * Function to setup municipality filter object, and add it to {@link customFilter} at index `[0]`
   *
   * @param selection - The option that is selected from the dropdownlist
   * */
  async function municipalityFilter(selection) {
    {
      customFilter.push(
        {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "VW_MUNICIPALITY_REGION_MAPPING",
            column: "MUNICIPALITY",
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Or",
          conditions: [
            {
              operator: "Is",
              value: selection == "All" ? "" : selection,
            },
          ],
        },
        {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "VW_CAFES_DATA",
            column: "CAFE_NAME",
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Or",
          conditions: [
            {
              operator: "Is",
              value: "",
            },
          ],
        },
        {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "VW_BUSINESS_LOCATIONS",
            column: "NAME",
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Or",
          conditions: [
            {
              operator: "Is",
              value: "",
            },
          ],
        }
      );
    }

    handleFilter();
  }
  /**
   * Function to setup region filter object, and add it to {@link customFilter} at index `[1]`
   *
   * @param selection - The option that is selected from the dropdownlist
   * */
  async function regionFilter(selection) {
    {
      customFilter.push(
        {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "VW_MUNICIPALITY_REGION_MAPPING",
            column: "REGION",
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Or",
          conditions: [
            {
              operator: "Is",
              value: selection == "All" ? "" : selection,
            },
          ],
        },
        {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "VW_MUNICIPALITY_REGION_MAPPING",
            column: "MUNICIPALITY",
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Or",
          conditions: [
            {
              operator: "Is",
              value: "",
            },
          ],
        },
        {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "VW_CAFES_DATA",
            column: "CAFE_NAME",
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Or",
          conditions: [
            {
              operator: "Is",
              value: "",
            },
          ],
        },
        {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "VW_BUSINESS_LOCATIONS",
            column: "NAME",
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Or",
          conditions: [
            {
              operator: "Is",
              value: "",
            },
          ],
        }
      );
    }

    handleFilter();
  }

  /**
   * **handleFilter**
   *
   * Handles the application of filter to the visuals. Called by {@link municipalityFilter}, {@link regionFilter} and {@link cafeFilter}
   * whenever they are invoked. This also has the *'list of visuals'* {@link visualList} and applies the filter to each
   * element in it.
   * */
  const handleFilter = async () => {
    try {
      visualList.forEach(async (visual) => {
        try {
          await visual.updateFilters(
            models.FiltersOperations.Replace,
            customFilter,
            models.FiltersLevel.Report
          );
        } catch (e) {
          console.error("Exception in handleFilter -", e);
        }
      });
    } catch (e) {
      console.error("Exception in handleFilter -", e);
    }
  };

  /**
   * This will provide the styles to the dropdown list defined below 👇
   *
   * Note: Default width is set as "200px". If you need to change the width, do sent the new value while calling this component
   * eg: dropDownStyle("400px")
   * */
  const dropDownStyle = (setWidth = "200px") => ({
    container: (baseStyles) => ({
      ...baseStyles,
      width: setWidth,
    }),

    control: (baseStyles) => ({
      ...baseStyles,
      background: "none",
      border: "none",
      boxShadow: "none",
      marginTop: "-5px",
      minHeight: "", // to remove some alignment issue seen
    }),

    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isDisabled ? "grey" : "white",
    }),

    menu: (baseStyles) => ({
      ...baseStyles,
      background: "rgba(150, 203, 210)",
    }),

    indicatorSeparator: (baseStyles, state) => ({
      ...baseStyles,
      background: state.isDisabled ? "grey" : "white",
    }),

    indicatorsContainer: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isDisabled ? "grey" : "white",
    }),

    // this is to change the color of the options in the dropdown list
    // when it is selcted vs not-selected
    // and also the color of the background when its selected/hover/default
    option: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isSelected ? "white" : "black",
      background: state.isSelected
        ? "#0C1A45"
        : state.isFocused
        ? "#80b1dc"
        : "transparent",
    }),
  });

  /**
   * UI dropdown component for Region selection
   * */
  const selectRegion = (
    <div className="filter">
      Region:
      <Tooltip
        title={
          showFilter < 1
            ? "Filter will enable after loading all the visuals"
            : ""
        }
      >
        <div>
          <Select
            className="filter-list"
            value={{
              value: defaultRegion,
              label: defaultRegion,
            }}
            isDisabled={!(showFilter >= 1)}
            isSearchable={false}
            onChange={(region) => {
              // this is needed to update the value shown in the dropdown
              setDefaultRegion(region.value);

              // THIS 👇 is needed to set the 'muncipality and cafe dropdown in the UI' as 'All'
              // whenever we change the Region in UI
              setDefaultMunicipality("All");
              setDefaultCafe("All");

              // THIS 👇 is the key to changeing the second drop-down-list.
              // The array should not be const, or var or let.....it should be a state!!
              setListState(mappingTable[region.value]);
              // to update the list of cafes
              setListCafes(cafeList(region.value, "All"));

              // for updating the session storage values to the latest selction to reflect
              // across the pages
              sessionStorage.setItem("region", region.value);
              sessionStorage.setItem("municipality", "All");
              regionFilter(region.value); // for applying the filter to the report
            }}
            styles={dropDownStyle()}
            options={regions.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </div>
      </Tooltip>
    </div>
  );

  /**
   * UI dropdown component for Municipality selction
   **/
  const selectMunicipality = (
    <div className="filter-municip" style={{ width: "260px" }}>
      Municipality:
      <Tooltip
        title={
          showFilter < 1
            ? "Filter will enable after loading all the visuals"
            : ""
        }
      >
        <div>
          <Select
            // THIS 👇 is needed to update the municipality to default/"All" in UI, depending on the region selection
            value={{
              value: defaultMunicipality,
              label: defaultMunicipality,
            }}
            className="filter-list-municip"
            isDisabled={!(showFilter >= 1)}
            isSearchable={false}
            onChange={(newValue) => {
              setDefaultMunicipality(newValue.value);
              setDefaultCafe("All");

              municipalityFilter(newValue.value);
              sessionStorage.setItem("municipality", newValue.value);
              setListCafes(cafeList(defaultRegion, newValue.value)); // to update the list of cafes
            }}
            styles={dropDownStyle("181px")}
            options={listState.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </div>
      </Tooltip>
    </div>
  );

  /**
   * UI dropdown for cafe selection
   */
  const selectCafe = (
    <div className="filter-municip" style={{ width: "260px" }}>
      Cafe:
      <Tooltip
        title={
          showFilter < 1
            ? "Filter will enable after loading all the visuals"
            : ""
        }
      >
        <div>
          <Select
            // THIS 👇 is needed to show the 'default muncipality' in dropdown in the UI
            value={{
              label: defaultCafe,
              value: defaultCafe,
            }}
            className="filter-list-cafe"
            isDisabled={!(showFilter >= 1)}
            isSearchable={true}
            onChange={(cafeName) => {
              setDefaultCafe(cafeName.value);
              cafeFilter(cafeName.value);
            }}
            styles={dropDownStyle("240px")}
            options={listCafes.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </div>
      </Tooltip>
    </div>
  );

  const Filter1 = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "VW_MUNICIPALITY_REGION_MAPPING",
      column: "REGION",
    },
    filterType: models.FilterType.Advanced,
    logicalOperator: "Or",
    conditions: [
      {
        operator: "Is",
        value: defaultRegion == "All" ? "" : defaultRegion,
      },
    ],
  };
  const Filter2 = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "VW_MUNICIPALITY_REGION_MAPPING",
      column: "MUNICIPALITY",
    },
    filterType: models.FilterType.Advanced,
    logicalOperator: "Or",
    conditions: [
      {
        operator: "Is",
        value: defaultMunicipality == "All" ? "" : defaultMunicipality,
      },
    ],
  };

  return (
    <div>
      <div className="map-body-qi">
        {/* For the guided navigation */}
        <Joyride
          steps={steps}
          run={run}
          hideCloseButton
          showSkipButton={true}
          callback={handlePopupCallback}
          styles={{
            options: {
              primaryColor: "#0C1A45",
              zIndex: 1100,
            },
          }}
          continuous={true}
        />
        <div className="heading-layer heading-layer-cafe">
          <div className="pageHead pageHead-cafe">
            <div className="buttons">
              {/******** Home button ********/}
              <button
                id="home-button"
                className="landing-page-buttons"
                onClick={() => navigate("/")}
              >
                Home
              </button>
              {/******** Feedback button ********/}
              <button
                id="feedback-button"
                className="landing-page-buttons"
                onClick={() => navigate("/feedback")}
              >
                Feedback
              </button>
            </div>
            <span className="pageTitle">SMB Analytics</span>
          </div>
          <div className="filterHead filterHead-cafe">
            {selectRegion}
            {selectMunicipality}
            {selectCafe}
          </div>
        </div>
        <div className="visual-layer visual-layer-cafe">
          <div className="reportList-qi">
            <div>
              <PowerBIEmbed
                embedConfig={{
                  type: "report", // Supported types: report, dashboard, tile, visual, and qna.
                  id: reportId,
                  embedUrl: embedUrl,
                  accessToken: props.token,
                  tokenType: models.TokenType.Aad, // Use models.TokenType.Aad if you're embedding for your organization.
                  pageName: "ReportSectionf1ca4119a9da4cc1e6e0",
                  filters: [Filter1, Filter2],
                  slicers: [],
                  settings: {
                    layoutType:
                      window.innerWidth <= 765
                        ? models.LayoutType.MobilePortrait
                        : models.LayoutType.Custom,
                    customLayout: {
                      displayOption: models.DisplayOption.ActualSize,
                      pagesLayout: {
                        ReportSectionf1ca4119a9da4cc1e6e0: {
                          defaultLayout: {
                            displayState: {
                              mode: models.VisualContainerDisplayMode.Visible,
                            },
                          },
                          visualsLayout: {
                            "6f55500e6f28a3dd18fd": {
                              displayState: {
                                mode: models.VisualContainerDisplayMode.Hidden,
                              },
                            },
                            d611bc7bb812af6efa9b: {
                              displayState: {
                                mode: models.VisualContainerDisplayMode.Hidden,
                              },
                            },
                          },
                        },
                      },
                    },
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false,
                      },
                      pageNavigation: {
                        visible: false,
                      },
                    },
                    visualSettings: {
                      visualHeaders: [
                        {
                          settings: {
                            // visible: false,
                          },
                        },
                      ],
                    },
                    background: models.BackgroundType.Transparent,
                  },
                }}
                getEmbeddedComponent={(embedObject) => {
                  setGraph1(embedObject);
                }}
                eventHandlers={
                  new Map([
                    [
                      "loaded",
                      function () {
                        console.log("Report loaded");
                      },
                    ],
                    [
                      "rendered",
                      function () {
                        console.log("Report rendered");
                        setShowFilter(showFilter.push(1));
                      },
                    ],
                    [
                      "error",
                      function (event) {
                        console.log(event.detail);
                      },
                    ],
                    ["visualClicked", () => console.log("visual clicked")],
                    ["pageChanged", (event) => console.log(event)],
                  ])
                }
                cssClassName="reportStyle"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMBAnalytics;
