import React, { useState } from "react";
import "./detailsPage.css";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import mappingData from "./MUNICIPALITY_REGION_MAPPING.json";
import { embedUrl, reportId } from "./Secret";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Tooltip,
} from "@mui/material";

const DemograhicComparisonRegion = (props) => {
  const [graph1, setGraph1] = useState();
  const navigate = useNavigate();
  const home = () => {
    navigate("/");
  };

  const visualList = [graph1];
  const regions = Object.keys(mappingData);

  // Array that determines the filter dropdowns state
  const [showFilter, setShowFilter] = useState([]);

  /**
   * defaultRegion state
   *
   * Thiby default will be the value fetched from the session storage.
   * Latter updated with the values selected (if any) by the region dropdown
   */
  const [defaultRegion, setDefaultRegion] = useState(
    sessionStorage.getItem("multiRegion") == null
      ? ["All"]
      : sessionStorage.getItem("multiRegion").split(",")
  );

  // The string that sets/determines the value of municipality dropdown
  //  whenever there is a change in state
  const [defaultMunicipality, setDefaultMunicipality] = useState(
    sessionStorage.getItem("multiMunicipality") == null
      ? ["All"]
      : sessionStorage.getItem("multiMunicipality").split(",")
  );

  /**
   *
   *
   * **Filter List**
   *
   *
   *
   * The list to which both Region and Municipal filter object gets added to.
   * In this end, this list/Array is passed in the *updateFilter()* method.
   * */
  let customFilter = [];

  /**
   * Function to setup region filter object, and add it to {@link customFilter} at index `[1]`
   *
   * @param selection - The options that are selected from the dropdownlist (an Array)
   * */
  async function regionFilter(selection) {
    {
      /**
       * Initializing filterCondition as an empty array
       * This will be filled with the 'list of conditions' that need to be passed to powerbi to get the filtering right
       */
      let filterConditon = [];
      // setting the custom filter for multi selection
      if (selection.includes("All")) {
        filterConditon.push({
          operator: "Is",
          value: "",
        });
      } else {
        selection.map((region) =>
          filterConditon.push({
            operator: "Is",
            value: region,
          })
        );
      }

      customFilter[0] = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "VW_MUNICIPALITY_REGION_MAPPING",
          column: "REGION",
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Or",
        conditions: filterConditon,
      };
      customFilter[1] = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "VW_MUNICIPALITY_REGION_MAPPING",
          column: "MUNICIPALITY",
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Or",
        conditions: [
          {
            operator: "Is",
            value: "",
          },
        ],
      };
    }

    handleFilter();
  }

  /**
   * **handleFilter**
   *
   * Handles the application of filter to the visuals. Called by {@link municipalityFilter} and {@link regionFilter}
   * whenever they are invoked. This also has the *'list of visuals'* from the `Graph.js` and applies the filter to each
   * element in it.
   * */
  const handleFilter = async () => {
    try {
      visualList.forEach(async (visual) => {
        await visual.updateFilters(
          models.FiltersOperations.Replace,
          customFilter,
          models.FiltersLevel.Report
        );
      });
    } catch (e) {
      console.error("Exception in handleFilter -", e);
    }
  };

  /**
   * UI dropdown component for Region selection
   * */
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const useCreateMunicipalList = (regionList) => {
    let municipList = [];
    municipList = [
      ...new Set(
        municipList.concat(...regionList.map((region) => mappingData[region]))
      ),
    ];
    // setListState(municipList);

    // THIS 👇 is needed to set the 'default muncipality dropdown in the UI' as 'All'
    // whenever we change the Region in UI
    setDefaultMunicipality(["All"]);
  };

  /**
   * **handleRegionChange**
   *
   * This handles any change made to the {@link selectRegion} dropdown
   * @param {*} event this contains stuff that is passed when pressing the dropdown options
   */
  const HandleRegionChange = (event) => {
    const {
      target: { value },
    } = event;

    // set the region state so as to refresh the dropdown UI
    setDefaultRegion(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    //to create the FilterObject to pass to the powerBi
    regionFilter(value);

    // to construct the municipality list
    useCreateMunicipalList(value);

    // to save the correct selections to the sessionStorage
    sessionStorage.setItem("multiRegion", value);
    sessionStorage.setItem("multiMunicipality", "All");
  };

  /**
   * UI dropdown component for Region selection
   * */
  const selectRegion = (
    <div>
      <FormControl>
        <InputLabel>Region</InputLabel>
        <Tooltip
          title={
            showFilter < 1
              ? "Filter will enable after loading all the visuals"
              : ""
          }
        >
          <Select
            multiple
            disabled={!(showFilter >= 1)}
            value={defaultRegion}
            onChange={HandleRegionChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) =>
              defaultRegion.length == 1
                ? selected
                : defaultRegion.length + " Regions Selected"
            }
            MenuProps={MenuProps}
            label="Region"
            labelWidth="250"
          >
            {regions.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={defaultRegion.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </FormControl>
    </div>
  );

  /***
   *
   *
   * INITAL FILTER VALUES SETTING SECTION 👇
   *
   *
   */
  var initialRegionsList = [];
  // setting the custom filter for multi selection
  if (defaultRegion.includes("All")) {
    initialRegionsList.push({
      operator: "Is",
      value: "",
    });
  } else {
    defaultRegion.map((region) =>
      initialRegionsList.push({
        operator: "Is",
        value: region,
      })
    );
  }
  var initialMunicipalityList = [];
  // setting the custom filter for multi selection
  if (defaultMunicipality.includes("All")) {
    initialMunicipalityList.push({
      operator: "Is",
      value: "",
    });
  } else {
    defaultMunicipality.map((region) =>
      initialMunicipalityList.push({
        operator: "Is",
        value: region,
      })
    );
  }
  const Filter1 = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "VW_MUNICIPALITY_REGION_MAPPING",
      column: "REGION",
    },
    filterType: models.FilterType.Advanced,
    logicalOperator: "Or",
    conditions: initialRegionsList,
  };
  const Filter2 = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "VW_MUNICIPALITY_REGION_MAPPING",
      column: "MUNICIPALITY",
    },
    filterType: models.FilterType.Advanced,
    logicalOperator: "Or",
    conditions: initialMunicipalityList,
  };

  return (
    <div>
      <div className="map-body-qi">
        <div className="heading-layer">
          <div className="pageHead">
            <div className="buttons">
              {/******** Home button ********/}
              <button
                id="home-button"
                className="landing-page-buttons"
                onClick={() => navigate("/")}
              >
                Home
              </button>
              {/******** Feedback button ********/}
              <button
                id="feedback-button"
                className="landing-page-buttons"
                onClick={() => navigate("/feedback")}
              >
                Feedback
              </button>
            </div>
            <span className="pageTitle"> Demographic Comparison - Region</span>
          </div>
          <div className="filterHead">{selectRegion}</div>
        </div>
        <div className="visual-layer">
          <div className="reportList-qi">
            <div>
              <PowerBIEmbed
                embedConfig={{
                  type: "report", // Supported types: report, dashboard, tile, visual, and qna.
                  id: reportId,
                  embedUrl: embedUrl,
                  accessToken: props.token,
                  tokenType: models.TokenType.Aad, // Use models.TokenType.Aad if you're embedding for your organization.
                  pageName: "ReportSection5835e5aff82a8c2bb62a",
                  filters: [Filter1, Filter2],
                  settings: {
                    layoutType:
                      window.innerWidth <= 765
                        ? models.LayoutType.MobilePortrait
                        : models.LayoutType.Custom,
                    customLayout: {
                      displayOption: models.DisplayOption.ActualSize,
                      pagesLayout: {
                        ReportSection5835e5aff82a8c2bb62a: {
                          defaultLayout: {
                            displayState: {
                              mode: models.VisualContainerDisplayMode.Visible,
                            },
                          },
                          visualsLayout: {
                            f01283ce2485f98a72fc: {
                              displayState: {
                                mode: models.VisualContainerDisplayMode.Hidden,
                              },
                            },
                          },
                        },
                      },
                    },
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false,
                      },
                      pageNavigation: {
                        visible: false,
                      },
                    },
                    visualSettings: {
                      visualHeaders: [
                        {
                          settings: {
                            visible: false,
                          },
                        },
                      ],
                    },
                    background: models.BackgroundType.Transparent,
                  },
                }}
                getEmbeddedComponent={(embedObject) => {
                  setGraph1(embedObject);
                }}
                eventHandlers={
                  new Map([
                    [
                      "loaded",
                      function () {
                        console.log("Report loaded");
                      },
                    ],
                    [
                      "rendered",
                      function () {
                        console.log("Report rendered");
                        setShowFilter(showFilter.push(1));
                      },
                    ],
                    [
                      "error",
                      function (event) {
                        console.log(event.detail);
                      },
                    ],
                    ["visualClicked", () => console.log("visual clicked")],
                    ["pageChanged", (event) => console.log(event)],
                  ])
                }
                cssClassName="reportStyle"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DemograhicComparisonRegion;
