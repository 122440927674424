import React, { useState, useEffect } from "react";
import "./detailsPage.css";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { embedUrl, reportId } from "./Secret";
import mappingData from "./MUNICIPALITY_REGION_MAPPING.json";
// import Tooltip from "@material-ui/core/Tooltip";
import { Tooltip } from "@mui/material";
import arrow from "./images/arrow.svg";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Joyride, { STATUS } from "react-joyride";
import joyrideCarousel from "./joyride/carousel";

const QuickInsights = (props) => {
  const [graph1, setGraph1] = useState();
  const navigate = useNavigate();

  const home = () => {
    navigate("/");
  };

  const visualList = [graph1];
  const regions = Object.keys(mappingData);
  const mappingTable = mappingData;

  /**
   * ************ Guided Navigation Section ************
   * */
  // 1. This determines the no of cards, its placement and content
  const steps = [
    {
      content:
        "Select the desired Region and Municipality you would like to explore",
      target: ".filterHead",
      disableBeacon: true,
    },
    {
      content: joyrideCarousel(
        "Business Success Probability",
        "Demographic Growth",
        "Financial data points",
        "Crowd Footfall"
      ),
      placement: "center",
      target: ".map-body",
    },
    {
      content: "Business Specific key points",
      placement: "right",
      target: window.innerWidth <= 765 ? "nowhere" : ".joyIcon2",
    },
    {
      content: "Demographic Data",
      placement: "right",
      target: window.innerWidth <= 765 ? "nowhere" : ".joyIcon3",
    },
    {
      content: "Compare demographics of Regions in Norway",
      placement: "right",
      target: window.innerWidth <= 765 ? "nowhere" : ".joyIcon4",
    },
    {
      content: "Compare demographics of Municipalities of Regions",
      placement: "right",
      target: window.innerWidth <= 765 ? "nowhere" : ".joyIcon5",
    },
    {
      content: "Dive into more",
      placement: "right",
      target: window.innerWidth <= 765 ? ".bm-burger-button" : "nowhere",
    },
  ];
  // 2. This determins the state of the card (we are using the browsersessionstorage to store the state)
  const run = sessionStorage.getItem("popUpAppear-qi") == null ? true : false;
  // 3. This changes the state after closing the pop-up
  const handlePopupCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      sessionStorage.setItem("popUpAppear-qi", false);
    }
  };

  // Array that determines the filter dropdowns state
  const [showFilter, setShowFilter] = useState([]);

  // setting defaultRegion - for default filter
  const [defaultRegion, setDefaultRegion] = useState(
    sessionStorage.getItem("region")
  );

  // The string that sets/determines the value of municipality dropdown
  //  whenever there is a change in state
  const [defaultMunicipality, setDefaultMunicipality] = useState(
    sessionStorage.getItem("municipality")
  );

  // Initializong the 'array' as a state (so that the screen can be re-rendered when this gets updated)
  // with the list of municipalities under 'defaultRegion'
  const [listState, setListState] = useState(mappingTable[defaultRegion]);

  /**
   * **Filter List**
   *
   * The list to which both Region and Municipal filter object gets added to.
   * In this end, this list/Array is passed in the *updateFilter()* method.
   * */
  let customFilter = [];
  /**
   * Function to setup municipality filter object, and add it to {@link customFilter} at index `[0]`
   *
   * @param selection - The option that is selected from the dropdownlist
   * */

  async function municipalityFilter(selection) {
    {
      customFilter[0] = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "VW_MUNICIPALITY_REGION_MAPPING",
          column: "MUNICIPALITY",
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Or",
        conditions: [
          {
            operator: "Is",
            value: selection == "All" ? "" : selection,
          },
        ],
      };
    }

    handleFilter();
  }
  /**
   * Function to setup region filter object, and add it to {@link customFilter} at index `[1]`
   *
   * @param selection - The option that is selected from the dropdownlist
   * */
  async function regionFilter(selection) {
    {
      customFilter[0] = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "VW_MUNICIPALITY_REGION_MAPPING",
          column: "REGION",
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Or",
        conditions: [
          {
            operator: "Is",
            value: selection == "All" ? "" : selection,
          },
        ],
      };
      customFilter[1] = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "VW_MUNICIPALITY_REGION_MAPPING",
          column: "MUNICIPALITY",
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Or",
        conditions: [
          {
            operator: "Is",
            value: "",
          },
        ],
      };
    }

    handleFilter();
  }

  /**
   * **handleFilter**
   *
   * Handles the application of filter to the visuals. Called by {@link municipalityFilter} and {@link regionFilter}
   * whenever they are invoked. This also has the *'list of visuals'* from the `Graph.js` and applies the filter to each
   * element in it.
   * */
  const handleFilter = async () => {
    try {
      visualList.forEach(async (visual) => {
        try {
          await visual.updateFilters(
            models.FiltersOperations.Replace,
            customFilter,
            models.FiltersLevel.Report
          );
        } catch (e) {
          console.error("Exception in handleFilter -", e);
        }
      });
    } catch (e) {
      console.error("Exception in handleFilter -", e);
    }
  };

  /**
   * This will provide the styles to the dropdown list defined below 👇
   *
   * Note: Default width is set as "200px". If you need to change the width, do sent the new value while calling this component
   * eg: dropDownStyle("400px")
   * */
  const dropDownStyle = (setWidth = "200px") => ({
    container: (baseStyles) => ({
      ...baseStyles,
      width: setWidth,
    }),

    control: (baseStyles) => ({
      ...baseStyles,
      background: "none",
      border: "none",
      boxShadow: "none",
      marginTop: "-5px",
      minHeight: "", // to remove some alignment issue seen
    }),

    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isDisabled ? "grey" : "white",
    }),

    menu: (baseStyles) => ({
      ...baseStyles,
      background: "rgba(150, 203, 210)",
    }),

    indicatorSeparator: (baseStyles, state) => ({
      ...baseStyles,
      background: state.isDisabled ? "grey" : "white",
    }),

    indicatorsContainer: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isDisabled ? "grey" : "white",
    }),

    // this is to change the color of the options in the dropdown list
    // when it is selcted vs not-selected
    // and also the color of the background when its selected/hover/default
    option: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isSelected ? "white" : "black",
      background: state.isSelected
        ? "#0C1A45"
        : state.isFocused
        ? "#80b1dc"
        : "transparent",
    }),
  });

  /**
   * UI dropdown component for Region selection
   * */
  const selectRegion = (
    <div className="filter">
      Region:
      <Tooltip
        title={
          showFilter < 1
            ? "Filter will enable after loading all the visuals"
            : ""
        }
      >
        <div>
          <Select
            className="filter-list"
            value={{
              value: defaultRegion,
              label: defaultRegion,
            }}
            isDisabled={!(showFilter >= 1)}
            isSearchable={false}
            onChange={(region) => {
              setDefaultRegion(region.value);
              regionFilter(region.value);

              // THIS 👇 is the key to changeing the second drop-down-list.
              // The array should not be const, or var or let.....it should be a state!!
              setListState(mappingTable[region.value]);
              // THIS 👇 is needed to set the 'default muncipality dropdown in the UI' as 'All'
              // whenever we change the Region in UI
              setDefaultMunicipality("All");

              sessionStorage.setItem("region", region.value);
              sessionStorage.setItem("municipality", "All");
            }}
            styles={dropDownStyle()}
            options={regions.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </div>
      </Tooltip>
    </div>
  );

  /**
   * UI dropdown component for Municipality selction
   **/
  const selectMunicipality = (
    <div className="filter-municip">
      Municipality:
      <Tooltip
        title={
          showFilter < 1
            ? "Filter will enable after loading all the visuals"
            : ""
        }
      >
        {/* This div is needed to show the tooltip */}
        <div>
          <Select
            // THIS 👇 is needed to show the 'default muncipality dropdown in the UI'
            value={{
              value: defaultMunicipality,
              label: defaultMunicipality,
            }}
            className="filter-list-municip"
            isDisabled={!(showFilter >= 1)}
            isSearchable={false}
            onChange={(newValue) => {
              setDefaultMunicipality(newValue.value);
              municipalityFilter(newValue.value);
              sessionStorage.setItem("municipality", newValue.value);
            }}
            // to change the width of the drop-down content
            styles={dropDownStyle("240px")}
            options={listState.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </div>
      </Tooltip>
    </div>
  );

  const Filter1 = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "VW_MUNICIPALITY_REGION_MAPPING",
      column: "REGION",
    },
    filterType: models.FilterType.Advanced,
    logicalOperator: "Or",
    conditions: [
      {
        operator: "Is",
        value: defaultRegion == "All" ? "" : defaultRegion,
      },
    ],
  };
  const Filter2 = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "VW_MUNICIPALITY_REGION_MAPPING",
      column: "MUNICIPALITY",
    },
    filterType: models.FilterType.Advanced,
    logicalOperator: "Or",
    conditions: [
      {
        operator: "Is",
        value: defaultMunicipality == "All" ? "" : defaultMunicipality,
      },
    ],
  };

  return (
    <div>
      <div className="map-body-qi">
        {/* For the guided navigation */}
        <Joyride
          steps={steps}
          run={run}
          hideCloseButton
          showSkipButton={true}
          callback={handlePopupCallback}
          styles={{
            options: {
              primaryColor: "#0C1A45",
              zIndex: 1100,
            },
          }}
          continuous={true}
        />
        <div className="heading-layer">
          <div className="pageHead">
            <div className="buttons">
              {/******** Home button ********/}
              <button
                id="home-button"
                className="landing-page-buttons"
                onClick={() => navigate("/")}
              >
                Home
              </button>
              {/******** Feedback button ********/}
              <button
                id="feedback-button"
                className="landing-page-buttons"
                onClick={() => navigate("/feedback")}
              >
                Feedback
              </button>
            </div>
            <span className="pageTitle"> Quick Insights</span>
          </div>
          <div className="filterHead">
            {selectRegion}
            {selectMunicipality}
          </div>
        </div>
        <div className="visual-layer">
          <div className="reportList-qi">
            <div>
              {/* Chainging from visual to report-embeding */}

              <PowerBIEmbed
                embedConfig={{
                  type: "report", // Supported types: report, dashboard, tile, visual, and qna.
                  id: reportId,
                  embedUrl: embedUrl,
                  accessToken: props.token,
                  tokenType: models.TokenType.Aad, // Use models.TokenType.Aad if you're embedding for your organization.
                  pageName: "ReportSection6ace04574709770a7c76",
                  filters: [Filter1, Filter2],
                  settings: {
                    layoutType:
                      window.innerWidth <= 765
                        ? models.LayoutType.MobilePortrait
                        : models.LayoutType.Custom,
                    customLayout: {
                      displayOption: models.DisplayOption.ActualSize,
                      pagesLayout: {
                        ReportSection6ace04574709770a7c76: {
                          defaultLayout: {
                            displayState: {
                              mode: models.VisualContainerDisplayMode.Visible,
                            },
                          },
                          visualsLayout: {
                            ed773ed7fbab183ca7ad: {
                              displayState: {
                                mode: models.VisualContainerDisplayMode.Hidden,
                              },
                            },
                            a656f0001595b7db3994: {
                              displayState: {
                                mode: models.VisualContainerDisplayMode.Hidden,
                              },
                            },
                            "5548acca30f6dbf14608": {
                              displayState: {
                                mode: models.VisualContainerDisplayMode.Hidden,
                              },
                            },
                            f48b0c2d52bf1491f873: {
                              displayState: {
                                mode: models.VisualContainerDisplayMode.Hidden,
                              },
                            },
                            "38305142538dcc114614": {
                              displayState: {
                                mode: models.VisualContainerDisplayMode.Hidden,
                              },
                            },
                          },
                        },
                      },
                    },
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false,
                      },
                      pageNavigation: {
                        visible: false,
                      },
                    },
                    visualSettings: {
                      visualHeaders: [
                        {
                          settings: {
                            visible: false,
                          },
                        },
                      ],
                    },
                    background: models.BackgroundType.Transparent,
                  },
                }}
                getEmbeddedComponent={(embedObject) => {
                  setGraph1(embedObject);
                }}
                eventHandlers={
                  new Map([
                    [
                      "loaded",
                      function () {
                        console.log("Report loaded");
                      },
                    ],
                    [
                      "rendered",
                      function () {
                        console.log("Report rendered");
                        setShowFilter(showFilter.push(1));
                      },
                    ],
                    [
                      "error",
                      function (event) {
                        console.log(event.detail);
                      },
                    ],
                    ["visualClicked", () => console.log("visual clicked")],
                    ["pageChanged", (event) => console.log(event)],
                  ])
                }
                cssClassName="reportStyle"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickInsights;
