import React, { useState, useEffect } from "react";
import "./Graph.css";
import axios from "axios";
import logo from "./images/BizWiseLogo_white.svg";
import miniLogo from "./images/Biz Wise logo - mini.svg";
import { useNavigate } from "react-router-dom";
import QuickInsights from "./QuickInsights";
import DemographicAnalysic from "./DemographicAnalysis";
import DemograhicComparisonRegion from "./DemographicComparisonRegion";
import DemograhicComparisonMunicipality from "./DemographicComparisonMunicipality";
import SMBAnalytics from "./SMBAnalytics";
import { slide as Menu } from "react-burger-menu";
import icon1_unsel from "./images/icon1.svg";
import icon2_unsel from "./images/icon2.svg";
import icon3_unsel from "./images/icon3.svg";
import icon4_unsel from "./images/icon4.svg";
import icon5_unsel from "./images/icon5.svg";
import icon1_selected from "./images/icon1-selected.svg";
import icon2_selected from "./images/icon2-selected.svg";
import icon3_selected from "./images/icon3-selected.svg";
import icon4_selected from "./images/icon4-selected.svg";
import icon5_selected from "./images/icon5-selected.svg";

const Graph = () => {
  // to disable scrolling in this page
  document.body.style.overflow = "hidden";

  const [tabValue, setTabValue] = useState("1");
  const [color1, setColor1] = useState("#D9D9D91F");
  const [color2, setColor2] = useState("#0C1A45");
  const [color3, setColor3] = useState("#0C1A45");
  const [color4, setColor4] = useState("#0C1A45");
  const [color5, setColor5] = useState("#0C1A45");
  const [isOpen, setIsOpen] = useState(false);
  const [token, setToken] = useState("");
  const [expandedMenu, setExpandeedMenu] = useState("displayNoItems");

  const [miniMenu, setMiniMenu] = useState("menu");

  /*********** For icon color-chage on selection ****************/
  const icon1 = tabValue == "1" ? icon1_selected : icon1_unsel;
  const icon2 = tabValue == "2" ? icon2_selected : icon2_unsel;
  const icon3 = tabValue == "3" ? icon3_selected : icon3_unsel;
  const icon4 = tabValue == "4" ? icon4_selected : icon4_unsel;
  const icon5 = tabValue == "5" ? icon5_selected : icon5_unsel;

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const getToken = () => {
      console.debug("calling token");
      const apiUrl = "https://smbadvisoryapp.azurewebsites.net/api/token";
      axios
        .get(apiUrl)
        .then((response) => {
          // checking the reposnse status
          if (response.status == 200) {
            console.debug("Token fetched");
            // Extract the response body
            const responseBody = response.data;

            // Now you can use the response data as needed
            setToken(responseBody);
          } else {
            // error status
            console.error("ERROR: ", response.statusText);
            console.error(response.data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors here
        });
    };
    getToken();
  }, []);

  const expandMenu = () => {
    setExpandeedMenu("displayItems");
    setMiniMenu("displayNoItems");
    setIsOpen(true);
  };

  const mergeMenu = () => {
    setExpandeedMenu("displayNoItems");
    setMiniMenu("menu");
  };
  const handleSubmit = (colorCode) => {
    setIsOpen(false);
    setTabValue(colorCode);
    setExpandeedMenu("displayNoItems");
    setMiniMenu("menu");
    switch (colorCode) {
      case "1":
        setColor1("#D9D9D91F");
        setColor2("#0C1A45");
        setColor3("#0C1A45");
        setColor4("#0C1A45");
        setColor5("#0C1A45");
        break;
      case "2":
        setColor1("#0C1A45");
        setColor3("#0C1A45");
        setColor4("#0C1A45");
        setColor2("#D9D9D91F");
        setColor5("#0C1A45");
        break;
      case "3":
        setColor1("#0C1A45");
        setColor2("#0C1A45");
        setColor4("#0C1A45");
        setColor3("#D9D9D91F");
        setColor5("#0C1A45");
        break;
      case "4":
        setColor1("#0C1A45");
        setColor2("#0C1A45");
        setColor3("#0C1A45");
        setColor4("#D9D9D91F");
        setColor5("#0C1A45");
        break;
      case "5":
        setColor1("#0C1A45");
        setColor2("#0C1A45");
        setColor3("#0C1A45");
        setColor5("#D9D9D91F");
        setColor4("#0C1A45");
        break;
    }
  };
  return (
    <div>
      <div className="map-body">
        <div className="sideBar">
          <div className={miniMenu} onMouseOver={expandMenu}>
            <img
              src={miniLogo}
              alt="Biz Wise"
              style={{ paddingBottom: "40px" }}
            />
            <div>
              <button style={{ background: color1 }} className="miniButtons">
                <img src={icon1} alt="dnb" className="menuIcons joyIcon1" />
              </button>
            </div>
            <div>
              <button style={{ background: color5 }} className="miniButtons">
                <img src={icon5} alt="dnb" className="menuIcons joyIcon2" />
              </button>
            </div>
            <div>
              <button style={{ background: color2 }} className="miniButtons">
                <img src={icon2} alt="dnb" className="menuIcons joyIcon3" />
              </button>
            </div>
            <div>
              <button style={{ background: color3 }} className="miniButtons">
                <img src={icon3} alt="dnb" className="menuIcons joyIcon4" />
              </button>
            </div>
            <div>
              <button style={{ background: color4 }} className="miniButtons">
                <img src={icon4} alt="dnb" className="menuIcons joyIcon5" />
              </button>
            </div>
          </div>

          <div className={expandedMenu}>
            <div className="ExpandMenuStyle">
              <img
                src={logo}
                alt="Biz Wise"
                style={{ paddingBottom: "40px", width: "170px" }}
              />
              <div>
                <button
                  style={{ background: color1 }}
                  className="verticalTabButtons"
                  onClick={() => handleSubmit("1")}
                >
                  <img src={icon1} alt="dnb" className="icons" />
                  <span className="buttonText">Quick Insights</span>
                </button>
              </div>
              <div>
                <button
                  style={{ background: color5 }}
                  className="verticalTabButtons"
                  onClick={() => handleSubmit("5")}
                >
                  <span>
                    <img src={icon5} alt="dnb" className="icons" />
                  </span>
                  <span className="buttonText">SMB Analytics</span>
                </button>
              </div>

              <div>
                <button
                  style={{ background: color2 }}
                  className="verticalTabButtons"
                  onClick={() => handleSubmit("2")}
                >
                  <img src={icon2} alt="dnb" className="icons" />
                  <span className="buttonText">Demographic Analytics</span>
                </button>
              </div>
              <div>
                <button
                  style={{ background: color3 }}
                  className="verticalTabButtons"
                  onClick={() => handleSubmit("3")}
                >
                  <img src={icon3} alt="dnb" className="icons" />
                  <span className="buttonText">
                    Demographic Comparison-Region
                  </span>
                </button>
              </div>
              <div>
                <button
                  style={{ background: color4 }}
                  className="verticalTabButtons"
                  onClick={() => handleSubmit("4")}
                >
                  <img src={icon4} alt="dnb" className="icons" />
                  <span className="buttonText">
                    Demographic Comparison-Municipality
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mobileViewMenu">
          <Menu
            isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
            className="burgerMenu"
            width={"230px"}
          >
            <div>
              <a href="/">
                <img src={logo} alt="Biz Wise" />
              </a>
            </div>
            <div>
              <button
                style={{ background: color1 }}
                className="verticalTabButtons"
                onClick={() => handleSubmit("1")}
              >
                <img src={icon1} alt="dnb" className="icons" />
                <span className="buttonText">Quick Insights</span>
              </button>
            </div>
            <div>
              <button
                style={{ background: color5 }}
                className="verticalTabButtons"
                onClick={() => handleSubmit("5")}
              >
                <span>
                  <img src={icon5} alt="dnb" className="icons" />
                </span>
                <span className="buttonText">SMB Analytics</span>
              </button>
            </div>
            <div>
              <button
                style={{ background: color2 }}
                className="verticalTabButtons"
                onClick={() => handleSubmit("2")}
              >
                <img src={icon2} alt="dnb" className="icons" />
                <span className="buttonText">Demographic Analytics</span>
              </button>
            </div>
            <div>
              <button
                style={{ background: color3 }}
                className="verticalTabButtons"
                onClick={() => handleSubmit("3")}
              >
                <img src={icon3} alt="dnb" className="icons" />
                <span className="buttonText">
                  Demographic Comparison-Region
                </span>
              </button>
            </div>
            <div>
              <button
                style={{ background: color4 }}
                className="verticalTabButtons"
                onClick={() => handleSubmit("4")}
              >
                <img src={icon4} alt="dnb" className="icons" />
                <span className="buttonText">
                  Demographic Comparison-Municipality
                </span>
              </button>
            </div>

            {/* The feedback button that navigates user to the fedback page */}
            <div>
              <button
                className="verticalTabButtons"
                onClick={() => navigate("/feedback")}
              >
                <span>
                  {/* <img src={icon1} alt="dnb" className="icons" /> */}
                </span>
                <span className="buttonText">Feedback</span>
              </button>
            </div>
          </Menu>
        </div>

        <div className="visual" onMouseOver={mergeMenu}>
          {(() => {
            switch (tabValue) {
              case "1":
                return <QuickInsights token={token} />;
              case "2":
                return <DemographicAnalysic token={token} />;
              case "3":
                return <DemograhicComparisonRegion token={token} />;
              case "4":
                return <DemograhicComparisonMunicipality token={token} />;
              case "5":
                return <SMBAnalytics token={token} />;
              default:
                <QuickInsights token={token} />;
            }
          }).call(this)}
        </div>
      </div>
    </div>
  );
};

export default Graph;
