import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function joyrideCarousel(...args) {
    const joyrideCarousal_settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerPadding: '80px'
      };
      
    return(
        <div>
          <Slider {...joyrideCarousal_settings}>
            {args.map( (item, index) => <div key={index}><h4>{item}</h4></div>)}
          </Slider>
        </div>
    )
}